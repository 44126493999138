import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP";
import { login } from "../../../redux/auth/actions";
import { store } from "../../../redux/store";
import AuthService from "../../../service/AuthService.js";
import User from "../../../service/UserService";
import { storeToken } from "../../../utils/LocalStorageHandler";
import FloatingLabel from "../../FloatingLabel/index";
import LoadingButton from "../../LoadingButton/LoadingButton.jsx";

const Index = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [formLoading, setFormLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    checkbox: false,
  });
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    setFormLoading(true);
    e.preventDefault();
    try {
      const res = await AuthService.login(formData);
      storeToken({
        access_token: res.data.accessToken,
        refresh_token: res.data.refreshToken,
      });
      const me = await User.me();

      if (!me?.data) return;

      store.dispatch(login(me.data));

      if (
        localStorage.redirectAfterLogin &&
        localStorage.redirectAfterLogin !== "/" &&
        localStorage.redirectAfterLogin.includes("dashboard")
      ) {
        navigate(localStorage.redirectAfterLogin);
        localStorage.redirectAfterLogin = "";
      } else {
        navigate("/dashboard/");
      }
    } catch (error) {
      setFormData({ email: "", password: "", checkbox: false });
    }
    setFormLoading(false);
  };
  return (
    <div className="flex items-center justify-center py-[100px]">
      <div className="   w-full  text-center   items-center  px-10 flex flex-col justify-between">
        <MainLogo
          style={{
            transform: width < 500 ? "scale(2)" : "scale(2.5)",
            margin: "2rem auto",
          }}
        />
        <div>
          <h1 className="sm:text-primary text-9xl font-bold">Anmelden</h1>
          <div className="flex  sm:text-xl text-base font-semibold text-[#868686] ">
            <p>
              Noch kein Account?{" "}
              <Link to="/register" className="text-[#0084F7]">
                Registrieren
              </Link>
            </p>
          </div>
        </div>
        <form className="mt-20 w-full grid gap-[30px]" onSubmit={handleSubmit}>
          <FloatingLabel
            label={"E-Mail Adresse"}
            value={formData.email}
            unFocusClass="!top-[16px] !text-[16px]"
            className={"sm:my-[12px] m-0"}
          >
            <input
              required
              value={formData.email}
              name="email"
              type="email"
              onChange={handleChange}
              className="border-[1px]  text-[20px] font-semibold border-[#d6d6d6] w-full rounded-[10px] p-3 outline-none bg-transparent  "
            />
          </FloatingLabel>
          <FloatingLabel
            label={"Passwort"}
            unFocusClass="!top-[16px] !text-[16px]"
            value={formData.password}
            className={"sm:my-[12px] m-0"}
          >
            <input
              required
              value={formData.password}
              onChange={handleChange}
              name="password"
              type="password"
              className="border-[1px]  text-[20px] font-semibold border-[#d6d6d6] w-full rounded-[10px] p-3 outline-none bg-transparent  "
            />
          </FloatingLabel>
          <Link to={"/forgetpassword"}>Passwort vergessen?</Link>
          <LoadingButton text={"Anmelden"} loading={formLoading} />
        </form>
      </div>
    </div>
  );
};

export default Index;
